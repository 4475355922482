import React, { useState } from "react";
import { Autocomplete } from "react-materialize";

type Props = {
  shops: { [key: string]: undefined }
  srcShopName: string
  destShopName: string
  defaultSrcShopValue?: string
  defaultDestShopValue?: string
}

const MoveFavoritesInput = ({ shops, srcShopName, destShopName, defaultSrcShopValue, defaultDestShopValue }: Props) => {

  return (
    <div className="mt-10">
      移行元
      <Autocomplete
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name={srcShopName}
        options={{
          data: shops
        }}
        defaultValue={defaultSrcShopValue}
      />
      移行先
      <Autocomplete
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name={destShopName}
        options={{
          data: shops
        }}
        defaultValue={defaultDestShopValue}
      />
    </div>
  );
};

export default MoveFavoritesInput;
